import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import MobilePageLayout, { HeaderWrapper } from '../components/MobilePageLayout'
import CheckoutForm from '../components/checkout/CheckoutForm'

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Leverans" />
      <MobilePageLayout>
        <HeaderWrapper>
          <h3>Leverans</h3>
        </HeaderWrapper>
        <CheckoutForm />
      </MobilePageLayout>
    </Layout>
  )
}

export default IndexPage

import React from 'react'
import styled from 'styled-components'
import { colors, fonts } from '../styles/constants'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Input = styled.input<ErrorProps>`
  border: 1px solid
    ${({ error }) => (error ? colors.darkPink : colors.darkBlue)};
  border-radius: 10px;
  padding: 0.55rem 1rem;
  line-height: 150%;
  color: ${colors.darkBlue};
  font-family: ${fonts.brown};
`

type InputType = 'text' | 'number' | 'password' | 'tel' | 'email'

interface ErrorProps {
  error: boolean
}

interface TextInputProps {
  title: string
  placeholder: string
  type: InputType
  name: string
  value: string
  error?: string | false
  onChange: (e: React.ChangeEvent<unknown>) => void
  onBlur: (e: React.FocusEvent<unknown>) => void
}

const TextInput: React.FC<TextInputProps> = ({
  title,
  placeholder,
  type,
  name,
  value,
  error = false,
  onChange,
  onBlur,
}) => {
  return (
    <Wrapper>
      <p>{title}</p>
      <Input
        error={error ? true : false}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
    </Wrapper>
  )
}

export default TextInput

import React from 'react'
import styled from 'styled-components'

import { OrderContext } from '../../utils/context/orderContext'

const PriceInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.4rem;
`

const FlexParagraph = styled.p`
  width: 50%;
`

interface TotalsProps {
  extraAmount: number
}

const Totals: React.FC<TotalsProps> = ({ extraAmount }) => {
  const { state, dispatch } = React.useContext(OrderContext)
  const [total, setTotal] = React.useState(0)

  React.useEffect(() => {
    const totalsArray = Object.values(state.items).map(
      ({ item, amount }) => item.price * amount,
    )
    const newTotal = totalsArray.reduce((a, b) => a + b, 0)
    setTotal(newTotal)
  })

  return (
    <PriceInfoWrapper>
      <FlexParagraph>
        Totalt: <b>{total + extraAmount} kr</b>
      </FlexParagraph>
      <p>Betalas med Swish vid upphämtning</p>
    </PriceInfoWrapper>
  )
}

export default Totals

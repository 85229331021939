import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import axios from 'axios'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { DividerWide } from '../MobilePageLayout'
import { Types } from '../../utils/context/reducers'
import RadioButton from './RadioButton'
import { DividerColor } from '../Divider'
import TextInput from '../TextInput'
import SelectInput from '../SelectInput'
import { Button } from '../Button'
import Totals from './Totals'
import { Order } from '../../utils/types/Order'
import { OrderContext } from '../../utils/context/orderContext'
import { colors } from '../../styles/constants'
import { useTimes } from '../../utils/hooks/useTimes'
import { deliveryEnabled } from '../../utils/pageConstants'

const Form = styled.form`
  margin-top: 1.4rem;
`

const InputWrapper = styled.div`
  margin-bottom: 0.5rem;
`

const InputWrapperLast = styled.div`
  margin-bottom: 1.5rem;
`

const ErrorText = styled.p`
  margin-top: 1rem;
  color: ${colors.darkPink};
`

const PrivacyText = styled.p`
  margin-top: 1rem;
  color: gray;
  font-size: 0.8rem;
`

const deliveryStrings: Record<string, string> = {
  pickup: 'upphämtning',
  delivery: 'leverans',
}

interface FormValues {
  deliveryMethod: string
  time: string
  phone: string
  name: string
  email: string
  address: string
}

const FormSchema = Yup.object().shape({
  deliveryMethod: Yup.string(),
  time: Yup.string().min(1).required(),
  phone: Yup.string().min(6).required(),
  email: Yup.string().email().required(),
  name: Yup.string().min(2).required(),
  address: Yup.string(),
})

const CheckoutForm: React.FC = () => {
  const { state, dispatch } = React.useContext(OrderContext)
  const [sendError, setError] = React.useState('')
  const times = useTimes()

  const initialFormValues: FormValues = {
    deliveryMethod: 'pickup',
    time: '',
    phone: '',
    email: '',
    name: '',
    address: '',
  }
  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={FormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const order: Order = {
          items: Object.values(state.items),
          deliveryTime: values.time,
          deliveryMethod: values.deliveryMethod,
          name: values.name,
          phone: values.phone,
          email: values.email,
          address: values.address,
          date: new Date(),
          status: 'ordered',
        }

        setSubmitting(true)
        setError('')

        // dev endpoint:
        // http://localhost:5001/ellas-cafe/us-central1/addOrder

        try {
          await axios.post(
            'https://us-central1-ellas-cafe.cloudfunctions.net/addOrder',
            {
              ...order,
            },
          )
          setSubmitting(false)
          dispatch({
            type: Types.Purge,
          })
          navigate('/confirmation', {
            state: { email: values.email },
          })
        } catch (e) {
          setSubmitting(false)
          setError(e.message)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <RadioButton
            name="deliveryMethod"
            value="pickup"
            checked={values.deliveryMethod === 'pickup'}
            onChange={() => setFieldValue('deliveryMethod', 'pickup')}
            title="Upphämtning"
            info="0 kr"
            subtitle="Sker vid Islandsvägen i Norra Ängby. Vi skickar ett SMS när det är redo!"
          />
          {deliveryEnabled && (
            <RadioButton
              name="deliveryMethod"
              value="delivery"
              checked={values.deliveryMethod === 'delivery'}
              onChange={() => setFieldValue('deliveryMethod', 'delivery')}
              title="Leverans (endast i Norra Ängby)"
              info="30 kr"
              subtitle="Vi skickar ett SMS när vi är nära!"
            />
          )}
          {values.deliveryMethod === 'delivery' && (
            <>
              <InputWrapperLast>
                <TextInput
                  title="Adress i Norra Ängby"
                  placeholder="Adress"
                  type="text"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  error={errors.address && touched.address && errors.address}
                />
              </InputWrapperLast>
            </>
          )}
          <InputWrapperLast>
            <SelectInput
              title={'Tid för ' + deliveryStrings[values.deliveryMethod]}
              name="time"
              value={values.time}
              placeholder="Välj en tid"
              values={times.map((time) => time.timeString)}
              error={errors.time && touched.time && errors.time}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapperLast>
          <DividerWide assetColor={DividerColor.Pink} />
          <InputWrapper>
            <TextInput
              title="Namn"
              placeholder="Namn"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name && errors.name}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              title="E-post"
              placeholder="hej@ellascafe.se"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email && errors.email}
            />
          </InputWrapper>
          <InputWrapperLast>
            <TextInput
              title="Telefonnummer"
              placeholder="070-123 45 67"
              type="tel"
              name="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              error={errors.phone && touched.phone && errors.phone}
            />
          </InputWrapperLast>
          <DividerWide assetColor={DividerColor.Pink} />
          <Totals extraAmount={values.deliveryMethod === 'delivery' ? 30 : 0} />
          <Button type="submit" disabled={isSubmitting} wide={true}>
            {isSubmitting ? 'Beställer...' : 'Bekräfta beställning'}
          </Button>
          {sendError && <ErrorText>Fel: {sendError}.</ErrorText>}
          <PrivacyText>Vi behandlar dina uppgifter enligt GDPR.</PrivacyText>
          <PrivacyText>
            Vi sätter hygien och minimering av smittorisk främst. Allt fika
            hanteras med handskar.
          </PrivacyText>
        </Form>
      )}
    </Formik>
  )
}

export default CheckoutForm

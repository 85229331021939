import * as React from 'react'
import axios from 'axios'

export interface Time {
  startTime: Date
  timeString: string
}

export const useTimes = (): Time[] => {
  const [times, setTimes] = React.useState<Time[]>([])

  React.useEffect(() => {
    axios
      .get('https://us-central1-ellas-cafe.cloudfunctions.net/getTimes')
      .then((response) => {
        if (response.data.times) {
          const parsedTimes = response.data.times.map(
            (time: { startTime: string; timeString: string }) => {
              return {
                startTime: new Date(time.startTime),
                timeString: time.timeString,
              }
            },
          )
          const sortedTimes = parsedTimes.sort(
            (a: Time, b: Time) => a.startTime.getTime() - b.startTime.getTime(),
          )
          setTimes(sortedTimes)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return times
}

import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles/constants'

import LogoAsset from '../images/logo.svg'
import LeftImageAsset from '../images/left-hero.png'
import RightImageAsset from '../images/right-hero.png'
import Divider from './Divider'

const HeaderWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: ${colors.darkPink};
`

const DividerWide = styled(Divider)`
  margin: 0 -2rem 1.5rem -2rem;
`

export { HeaderWrapper, DividerWide }

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Background = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -100;
  background-color: ${colors.lightBlue};
`

const ImageWrapper = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: -15rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: -99;
`

const LeftImage = styled.img`
  max-width: 28rem;
  width: 30vw;
  min-width: 15rem;
  object-fit: contain;
  height: 100%;
`

const RightImage = styled.img`
  max-width: 23rem;
  width: 28vw;
  min-width: 12rem;
  object-fit: contain;
  height: 100%;
`

const ContentWrapper = styled.div`
  padding: 3rem 0;
  max-width: 28rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  width: 9rem;
  margin-bottom: 3rem;
`

const ContentBox = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 2rem;
  flex: 1;
  width: 100%;
`

interface LayoutProps {
  children: React.ReactNode
}

const MobilePageLayout: React.FC<LayoutProps> = ({ children }) => (
  <>
    <PageWrapper>
      <ContentWrapper>
        <Logo alt="Ellas Café logo" src={LogoAsset} />
        <ContentBox>{children}</ContentBox>
      </ContentWrapper>
    </PageWrapper>
    <ImageWrapper>
      <LeftImage src={LeftImageAsset} alt="Fika" />
      <RightImage src={RightImageAsset} alt="Fika" />
    </ImageWrapper>
    <Background />
  </>
)

export default MobilePageLayout

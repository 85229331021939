import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/constants'

const RadioWrapper = styled.div`
  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    width: 100%;
    display: flex;
    align-items: center;

    border: 1px solid ${colors.darkBlue};
    padding: 1rem;
    border-radius: 5rem;
  }

  input[type='radio']:checked + label {
    background-color: ${colors.lightBlue};
  }
`

const Info = styled.div`
  flex: 1;
  text-align: right;
`

const CheckedIndicator = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  border: 1px solid ${colors.darkBlue};
  margin-right: 1rem;
`

const Circle = styled.div<CheckedProps>`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${colors.darkBlue};
  border-radius: 0.25rem;
  display: ${({ checked }) => (checked ? 'inline-block' : 'none')};
`
const Subtitle = styled.p`
  color: ${colors.darkBlue};
  margin-top: 1rem;
  opacity: 0.55;
`

const Wrapper = styled.div`
  margin-bottom: 1rem;
`

interface CheckedProps {
  checked: boolean
}

interface RadioButtonProps {
  onChange: () => void
  name: string
  value: string
  checked: boolean
  title: string
  info: string
  subtitle?: string
}

const RadioButton: React.FC<RadioButtonProps> = ({
  onChange,
  name,
  value,
  checked,
  title,
  info,
  subtitle,
}) => {
  return (
    <Wrapper>
      <RadioWrapper>
        <input
          type="radio"
          name={name}
          value={value}
          id={value}
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor={value}>
          <CheckedIndicator>
            <Circle checked={checked} />
          </CheckedIndicator>
          <p>{title}</p>
          <Info>{info}</Info>
        </label>
      </RadioWrapper>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  )
}

export default RadioButton

import React from 'react'
import styled from 'styled-components'
import { colors, fonts } from '../styles/constants'
import SelectArrowAsset from '../images/select-arrow.svg'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Select = styled.select<ErrorProps>`
  border: 1px solid
    ${({ error }) => (error ? colors.darkPink : colors.darkBlue)};
  border-radius: 10px;
  padding: 0.4rem 2rem 0.4rem 1rem;
  line-height: 150%;
  height: 2.4rem;
  font-family: ${fonts.brown};
  color: ${colors.darkBlue};

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(${SelectArrowAsset});
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
`

interface ErrorProps {
  error: boolean
}

interface SelectInputProps {
  title: string
  name: string
  placeholder: string
  error?: string | false
  value: string
  values: string[]
  onChange: (e: React.ChangeEvent<unknown>) => void
  onBlur: (e: React.FocusEvent<unknown>) => void
}

const SelectInput: React.FC<SelectInputProps> = ({
  title,
  name,
  placeholder,
  value,
  values,
  error = false,
  onChange,
  onBlur,
}) => {
  return (
    <Wrapper>
      <p>{title}</p>
      <Select
        error={error ? true : false}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        style={{ display: 'block' }}
      >
        <option value="">{placeholder}</option>
        {values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Select>
    </Wrapper>
  )
}

export default SelectInput
